import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import getGraphqlImage from '../../utils/get-graphql-image';
import LinkButton from './link-button';

const CourseCard = ({ title, prefix, slug, image, subtitle, body, ctaText }) => {
  const imageSizes = getGraphqlImage(image);
  const url = `${prefix}/${slug}`;

  return (
    <div className="course-card" to={url}>
      <div className="course-card__content">
        <h2 className="course-card__title">
          <Link to={url}>{title}</Link>
        </h2>
        <strong className="course-card__subtitle">
          <Link to={url}>{subtitle}</Link>
        </strong>
        <div className="course-card__body" dangerouslySetInnerHTML={{ __html: body }} />
        <LinkButton alt to={url}>
          {ctaText}
        </LinkButton>
      </div>
      {imageSizes && (
        <Link className="course-card__image-wrapper" to={url}>
          <Img className="course-card__image" alt="" fluid={imageSizes} />
        </Link>
      )}
    </div>
  );
};

CourseCard.defaultProps = {
  ctaText: 'Learn more',
};

export default CourseCard;
